import { useEffect, useState } from 'react';
import ImageContainer from './ImageContainer';

const BlogPreview = () => {
    const blogUrl = import.meta.env.VITE_BLOG_LINK;
    const blogRss = import.meta.env.VITE_BLOG_RSS;

    const [articles, setArticles] = useState([]);
    const [avatarAuthor, setAvatarAuthor] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch(blogRss, {
                    credentials: 'omit', // Disable cookie sending
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const avatarAuthor = data.feed.image;
                // Get the number of articles from .env
                const articlesCount = parseInt(import.meta.env.VITE_BLOG_ARTICLES_COUNT, 10) || 4;
                const latestArticles = data.items.slice(0, articlesCount);

                // Find the first <figure> block in the description of the first article
                latestArticles.forEach(article => {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(article.description, 'text/html');
                    const firstFigure = doc.querySelector('figure img');
                    if (firstFigure) {
                        article.figureImageUrl = firstFigure.src; // Add image URL to the article object
                    }
                });

                setAvatarAuthor(avatarAuthor);
                setArticles(latestArticles);
                setLoading(false);
            } catch (error) {
                setError('Failed to load articles. Please visit our blog for more.');
                setLoading(false);
            }
        };

        fetchArticles();
    }, []);

    if (loading) {
        return <div className="text-center py-8 text-gray dark:text-bone">Loading...</div>;
    }

    if (error || articles.length === 0) {
        return (
            // If Error
            <section>
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 text-center">
                    <p className="text-gray dark:text-bone">
                        Failed to upload articles. Please go to the blog page for up-to-date information.
                    </p>
                    <a href={blogUrl} target="_blank" rel="noopener noreferrer" className="mt-4 inline-flex items-center font-medium text-gray dark:text-bone dark:hover:text-light-gray">
                        Go to the blog
                        <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </a>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                {/* Title */}
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                    <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray dark:text-bone">
                        The latest post on our blog
                    </h2>
                    <p className="font-light text-gray dark:text-bone sm:text-xl">
                        Discover more about our projects and insights by checking out our blog. Stay updated with the latest trends and news in the blockchain industry
                    </p>
                </div> 
                {/* Articles */}
                <div className="grid gap-8 xl:grid-cols-3 sm:grid-cols-2">
                    {articles.map((article, index) => (
                        <article key={index} className="p-6 rounded-2xl border border-gray shadow-md dark:border-bone transition-transform duration-300 hover:scale-105">
                            {/* Caterories */}
                            <div className="flex justify-between items-start mb-5 text-gray dark:text-bone">
                                <div className="flex flex-wrap gap-1 lg:gap-2 content-start h-auto">
                                    {article.categories?.length > 0 && (
                                        <span className="text-gray-400 text-[10px] sm:text-xs inline-flex items-center cursor-default pr-4 break-words">
                                            {article.categories?.sort((a, b) => a.localeCompare(b)).join('/')}
                                        </span>
                                    )}
                                </div>
                                <span className="text-sm flex-shrink-0">{new Date(article.pubDate).toLocaleDateString()}</span>
                            </div>
                            {/* Title */}
                            <h2 className="text-xs sm:text-xl font-semibold leading-tight text-gray dark:text-bone dark:hover:text-light-gray cursor-default h-8 sm:h-14 overflow-hidden line-clamp-2">
                                <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
                            </h2>
                            {/* Image */}
                            {article.figureImageUrl && (
                                <div className="image-container flex items-center mx-auto my-4">
                                    <img src={article.figureImageUrl} alt={article.title} className="image rounded-lg shadow-md" />
                                </div>
                            )}
                            {/* Author and Read more */}
                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-4">
                                    <img className="w-7 h-7 rounded-full" src="./logo.png" alt={article.author} />
                                    <span className="font-medium text-gray dark:text-bone">
                                        {article.author || '@ksalab'}
                                    </span>
                                </div>
                                <span className="inline-flex items-center font-medium text-gray dark:text-bone dark:hover:text-light-gray">
                                    Read the&nbsp;
                                    <a href={article.link} target="_blank" rel="noopener noreferrer" className="inline-flex items-center font-medium">
                                        article
                                        <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </a>
                                </span>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
            {/* Link to blog */}
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center">
                <p className="text-gray dark:text-bone">
                Explore our blog for more articles.
                </p>
                <a href={blogUrl} target="_blank" rel="noopener noreferrer" className="mt-4 inline-flex items-center font-medium text-gray dark:text-bone dark:hover:text-light-gray">
                    Go to the blog
                    <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                </a>
            </div>

        </section>
    );
};

export default BlogPreview;