import { useEffect, useState } from 'react';
import { getValidatorCommission, getTokenName, getTokenPrice, getAnnualProvisions, getTotalStaked, calculateAPR } from './api';


const ProjectCard = ({ index, project, openModal }) => {
    const [apr, setApr] = useState(null);

    useEffect(() => {
        const fetchApr = async () => {
            try {
                // Check for api_url before executing requests
                if (!project.api_url || project.api_url.trim() === '') {
                    setApr(null);
                    return;
                }

                const annualProvisions = await getAnnualProvisions(project.api_url, project.apr_check);
                const totalStaked = await getTotalStaked(project.api_url);
                
                // Check for data before calculating
                if (annualProvisions === null || totalStaked === null) {
                    setApr(null);
                    return;
                }

                const calculatedApr = calculateAPR(annualProvisions, totalStaked);
                setApr(calculatedApr);

                // Add APR to project object
                project.apr = calculatedApr;

            } catch (error) {
                setApr(null);
            }
        };

        const fetchTokenData = async () => {
            const tokenName = await getTokenName(project.api_url, project.type);
    
            if (tokenName === null) {
                return;
            }
    
            const tokenFullName = tokenName.slice(1).toUpperCase();
            const tokenPrice = await getTokenPrice(project);

            project.denom = tokenFullName;
    
            if (tokenPrice !== null) {
                project.price = tokenPrice;
            }
            else {
                project.price = null;
            }
        };

        const fetchValidator = async () => {
            const commissionRate = await getValidatorCommission(project.validator, project.api_url, project.type);

            if (commissionRate === null) {
                return;
            }

            project.commission = commissionRate;
        };

        fetchApr();
        fetchTokenData();
        fetchValidator();

    }, [project.api_url, project.name]);


    return (
        <div key={index} className="rounded-2xl border border-gray p-6 shadow-sm dark:border-bone dark:hover:border-light-gray transition-transform duration-300 hover:scale-105">
            <div className="h-auto w-full">
                <div className="flex flex-col md:flex-row justify-center md:justify-start item-center gap-[10px] sm:gap-3">
                    {/* Logo */}
                    <div className="flex-shrink-0">
                        <img className="mx-auto h-10 sm:h-20" src={project.logo} alt={project.name} />
                    </div>
                    {/* Name and APR */}
                    <div className="my-auto text-[10px] sm:text-sm sm:gap-[10px]">
                        <div className="text-xs sm:text-xl font-semibold leading-tight text-gray dark:text-bone cursor-default">
                            {project.name.charAt(0).toUpperCase() + project.name.slice(1)}
                        </div>
                        <div className="pt-2 text-[10px] sm:text-xs leading-tight text-gray dark:text-light-gray cursor-default">
                            APR: <span className="text-gray dark:text-light-gray">{apr !== null ? `${apr}%` : 'TBA'}</span>
                        </div>
                    </div>
                </div>
                {/* Tags */}
                <div className="my-2 flex flex-wrap items-center justify-center gap-1 sm:gap-2">
                    <svg className="hidden md:inline-flex w-4 h-4 text-gray dark:text-bone" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.583 8.445h.01M10.86 19.71l-6.573-6.63a.993.993 0 0 1 0-1.4l7.329-7.394A.98.98 0 0 1 12.31 4l5.734.007A1.968 1.968 0 0 1 20 5.983v5.5a.992.992 0 0 1-.316.727l-7.44 7.5a.974.974 0 0 1-1.384.001Z"/>
                    </svg>
                    {project.tags?.length > 0 && (
                        <span className="text-gray dark:text-light-gray text-[10px] sm:text-xs inline-flex items-center cursor-default">
                            {project.tags?.sort((a, b) => a.localeCompare(b)).join('/')}
                        </span>
                    )}
                </div>
                {/* Links */}
                <div className="flex flex-wrap justify-center h-auto py-2 gap-2.5">
                    <div className={`flex flex-wrap w-full ${project.validatorUrl ? 'md:w-3/4' : 'md:w-full'} md:flex-nowrap md:inline-flex gap-2.5 justify-center`}>
                        {project.explorerUrl && project.validatorUrl && project.validator && (
                            <a href={project.explorerUrl + project.validatorUrl + project.validator} target="_blank" className="w-full md:w-11/12 inline-flex items-center rounded-xl px-4 py-1 text-sm font-medium outline-none border border-gray dark:border-bone dark:hover:text-light-gray text-gray dark:text-bone">
                                Stake!
                            </a>
                        )}
                        <button onClick={() => openModal(project)} className="w-full md:w-auto inline-flex items-center rounded-xl px-4 py-1 text-sm font-medium outline-none  border border-gray dark:border-bone dark:hover:text-light-gray text-gray dark:text-bone" aria-label="Calculate your earnings">
                            <svg className="-ms-2 md:ms-2 me-2 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5.97 4.06L14.09 6l1.41 1.41L16.91 6l1.06 1.06-1.41 1.41 1.41 1.41-1.06 1.06-1.41-1.4-1.41 1.41-1.06-1.06 1.41-1.41-1.41-1.42zm-6.78.66h5v1.5h-5v-1.5zM11.5 16h-2v2H8v-2H6v-1.5h2v-2h1.5v2h2V16zm6.5 1.25h-5v-1.5h5v1.5zm0-2.5h-5v-1.5h5v1.5z"></path>
                            </svg>
                            <span className="md:hidden">Calc</span>
                        </button>
                    </div>
                    {project.explorerUrl && (
                        <a href={project.explorerUrl} target="_blank" className="w-full md:w-auto inline-flex items-center rounded-xl px-4 py-1 text-sm font-medium outline-none  border border-gray dark:border-bone dark:hover:text-light-gray text-gray dark:text-bone">
                            Explorer
                        </a>
                    )}
                    {project.servicesUrl && (
                        <a href={project.servicesUrl} target="_blank" className="w-full md:w-auto inline-flex items-center rounded-xl px-4 py-1 text-sm font-medium outline-none  border border-gray dark:border-bone dark:hover:text-light-gray text-gray dark:text-bone">
                            Services
                        </a>
                    )}
                    {project.descriptionUrl && (
                        <a href={project.descriptionUrl} target="_blank" className="w-full md:w-auto inline-flex items-center rounded-xl px-4 py-1 text-sm font-mediumoutline-none  border border-gray dark:border-bone dark:hover:text-light-gray text-gray dark:text-bone">
                            Description
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
