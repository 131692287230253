// Modal.jsx
import { useState } from 'react';

const ProjectCardCalcModal = ({ isOpen, onClose, project }) => {
    const [sliderValue, setSliderValue] = useState(1);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
            <div className="bg-light-gray dark:bg-gray border border-gray dark:border-bone p-6 rounded-2xl shadow-lg max-w-lg w-full">
                {/* Title */}
                <div className="flex flex-col items-center justify-center">
                    <img className="mx-auto h-10 sm:h-20" src={project.logo} alt={project.name} />
                    <h2 className="text-gray dark:text-bone text-center text-gl sm:text-xl font-semibold mt-4 mb-8">
                        Calculate your income for {project.name}
                    </h2>
                </div>
                {/* Rules */}
                <div className="flex flex-row justify-between items-center mb-4 text-center">
                    <span className="text-sm sm:text-lg text-gray dark:text-bone">Estimated earn per year (APR): </span>
                    <span className="text-lg sm:text-xl font-medium text-jungle-green dark:text-jungle-green">{project.apr && project.apr !== null ? `~${project.apr}%` : 'TBA'}</span>
                </div>
                {/* Your stake */}
                <div className="flex flex-col justify-between items-center mb-4 text-center">
                    <div className="flex flex-row justify-between items-center w-full">
                        <span className="text-sm sm:text-lg text-gray dark:text-bone">Your stake: </span>
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm sm:text-lg text-gray dark:text-bone">
                            {sliderValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {project.denom}
                        </span>
                    </div>
                    <div className="flex flex-row justify-end items-center w-full">
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-md text-gray dark:text-bone">
                            {`~$${
                                isNaN((sliderValue * project.price))
                                ? "0"
                                : ((sliderValue * project.price).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            }`}
                        </span>
                    </div>
                </div>
                {/* Slider */}
                <div className="relative mb-6">
                    <input id="default-range" type="range" min="1" max="1000000" value={sliderValue} className="w-full h-2 bg-gray rounded-lg appearance-none cursor-pointer dark:bg-bone" onChange={(e) => setSliderValue(e.target.value)} tabIndex="0" style={{"--range-value": sliderValue, "--max-value": "1000000", "--range-color": "#20ad84"}} />
                </div>
                {/* Daily APR */}
                <div className="flex flex-col justify-between items-center text-center border-b-2 border-gray dark:border-bone">
                    <div className="flex flex-row justify-between items-center w-full">
                        <span className="text-sm sm:text-md text-gray dark:text-bone">Daily</span>
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm sm:text-md text-gray dark:text-bone">
                            {
                                isNaN((sliderValue * project.apr / 100 / 365 * project.commission))
                                ? "0"
                                : ((sliderValue * project.apr / 100 / 365 * project.commission).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {project.denom}
                        </span>
                    </div>
                    <div className="flex flex-row justify-end items-center w-full">
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm text-gray dark:text-bone">
                            {`~$${
                                isNaN((sliderValue * project.price * project.apr / 100 / 365 * project.commission))
                                    ? "0"
                                    : ((sliderValue * project.price * project.apr / 100 / 365 * project.commission).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            }`}
                        </span>
                    </div>
                </div>
                {/* Monthly APR */}
                <div className="flex flex-col justify-between items-center text-center border-b-2 border-gray dark:border-bone">
                    <div className="flex flex-row justify-between items-center w-full">
                        <span className="text-sm sm:text-md text-gray dark:text-bone">Monthly</span>
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm sm:text-md text-gray dark:text-bone">
                            {
                                isNaN((sliderValue * project.apr / 100 / 12 * project.commission))
                                ? "0"
                                : ((sliderValue * project.apr / 100 / 12 * project.commission).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {project.denom}
                        </span>
                    </div>
                    <div className="flex flex-row justify-end items-center w-full">
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm text-gray dark:text-bone">
                            {`~$${
                                isNaN((sliderValue * project.price * project.apr / 100 / 12 * project.commission))
                                ? "0"
                                : ((sliderValue * project.price * project.apr / 100 / 12 * project.commission).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            }`}
                        </span>
                    </div>
                </div>
                {/* Yearly APR */}
                <div className="flex flex-col justify-between items-center text-center">
                    <div className="flex flex-row justify-between items-center w-full">
                        <span className="text-sm sm:text-md text-gray dark:text-bone">Yearly</span>
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm sm:text-md text-gray dark:text-bone">
                            {
                                isNaN((sliderValue * project.apr / 100 * project.commission))
                                ? "0"
                                : ((sliderValue * project.apr / 100 * project.commission).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {project.denom}
                        </span>
                    </div>
                    <div className="flex flex-row justify-end items-center w-full">
                        <span onChange={(e) => setSliderValue(e.target.value)} className="text-sm text-gray dark:text-bone">
                            {`~$${
                                isNaN((sliderValue * project.price * project.apr / 100 * project.commission))
                                ? "0"
                                : ((sliderValue * project.price * project.apr / 100 * project.commission).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            }`}
                        </span>
                    </div>
                </div>
                {/* Buttons */}
                <div className="flex flex-row items-center justify-between mt-8">
                    <button onClick={onClose} className="px-4 py-2 border border-gray dark:border-bone text-gray dark:text-bone dark:hover:text-light-gray rounded-2xl dark:hover:border-light-gray">
                        Cancel
                    </button>

                    <a href={project.explorerUrl + project.validatorUrl + project.validator} target="_blank" className="px-4 py-2 bg-jungle-green dark:bg-jungle-green text-gray  dark:text-bone rounded-2xl dark:hover:text-light-gray">
                        Stake!
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProjectCardCalcModal;