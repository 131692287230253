import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from "./pages/Home";
// import Privacy from "./pages/Privacy";

import './App.css'

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    // Попробуем получить значение из localStorage, если оно там есть
    const savedTheme = localStorage.getItem('darkMode');
    return savedTheme ? JSON.parse(savedTheme) : true;
  });

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    // Сохраняем текущее состояние темы в localStorage
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route path='/' element={<Home darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        {/* <Route path='/privacy' element={<Privacy />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
