const Footer = () => {
    
    const mainUrl = import.meta.env.VITE_MAIN_LINK;
    const blogUrl = import.meta.env.VITE_BLOG_LINK;
    const discordUrl = import.meta.env.VITE_DISCORD_LINK;
    const explorerUrl = import.meta.env.VITE_EXPLORER_LINK;
    const githubUrl = import.meta.env.VITE_GITHUB_LINK;
    const servicesUrl = import.meta.env.VITE_SERVICES_LINK;
    const twitterUrl = import.meta.env.VITE_TWITTERX_LINK;
    const warpcastUrl = import.meta.env.VITE_WARPCAST_LINK;

    const today = new Date();
    const year = today.getFullYear();

    return (
        <footer className="p-4 sm:p-6">
            <div className="mx-auto max-w-screen-xl">
                <div className="md:flex md:justify-between">
                    {/* Logo */}
                    <div className="mb-6 md:mb-0">
                        <a href={mainUrl} className="flex items-center" aria-label="Logo">
                            <img src="./logo.png" className="mr-3 h-8" alt="Logo" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap text-gray dark:text-bone">
                                KSALAB
                            </span>
                        </a>
                    </div>
                    {/* Resources */}
                    <div className="grid grid-cols-2 gap-8 sm:gap-6">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray uppercase dark:text-bone">
                                Resources
                            </h2>
                            <ul>
                                {servicesUrl && (
                                    <li className="mb-0 sm:mb-4">
                                        <a href={servicesUrl} className="block py-2 px-3 md:p-0 text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Services">
                                            Services
                                        </a>
                                    </li>
                                )}
                                {explorerUrl && (
                                    <li>
                                        <a href={explorerUrl} className="block py-2 px-3 md:p-0 text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Explorer">
                                            Explorer
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray uppercase dark:text-bone">Follow us</h2>
                            <ul>
                                {githubUrl && (
                                    <li className="mb-0 sm:mb-4">
                                        <a href={githubUrl} className="block py-2 px-3 md:p-0 text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Github">
                                            Github
                                        </a>
                                    </li>
                                )}
                                {blogUrl && (
                                    <li className="mb-0 sm:mb-4">
                                        <a href={blogUrl} className="block py-2 px-3 md:p-0 text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Blog">
                                            Blog
                                        </a>
                                    </li>
                                )}
                                {warpcastUrl && (
                                    <li className="mb-0 sm:mb-4">
                                        <a href={warpcastUrl} className="block py-2 px-3 md:p-0 text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Warpcast">
                                            Warpcast
                                        </a>
                                    </li>
                                )}
                                {twitterUrl && (
                                    <li className="mb-0 sm:mb-4">
                                        <a href={twitterUrl} className="block py-2 px-3 md:p-0 text-gray dark:text-bone dark:hover:text-light-gray" aria-label="X (Twitter)">
                                            X (Twitter)
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray sm:mx-auto dark:border-bone lg:my-8" />
                <div className="flex flex-wrap sm:items-center justify-center md:justify-between">
                    <span className="text-sm sm:text-center text-gray dark:text-bone">
                        © 2022-{year} <a href="https://ksalab.xyz/" className="dark:hover:text-light-gray">KSALAB</a> ™. All Rights Reserved.
                    </span>
                    <div className="flex mt-4 space-x-6 items-center sm:justify-center sm:mt-0">
                        {/* medium */}
                        {blogUrl && (
                            <a href={blogUrl} target="_blank" className="text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Blog">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 -55 256 256">
                                    <path d="M72.2009141,1.42108547e-14 C112.076502,1.42108547e-14 144.399375,32.5485469 144.399375,72.6964154 C144.399375,112.844284 112.074049,145.390378 72.2009141,145.390378 C32.327779,145.390378 0,112.844284 0,72.6964154 C0,32.5485469 32.325326,1.42108547e-14 72.2009141,1.42108547e-14 Z M187.500628,4.25836743 C207.438422,4.25836743 223.601085,34.8960455 223.601085,72.6964154 L223.603538,72.6964154 C223.603538,110.486973 207.440875,141.134463 187.503081,141.134463 C167.565287,141.134463 151.402624,110.486973 151.402624,72.6964154 C151.402624,34.9058574 167.562834,4.25836743 187.500628,4.25836743 Z M243.303393,11.3867175 C250.314,11.3867175 256,38.835526 256,72.6964154 C256,106.547493 250.316453,134.006113 243.303393,134.006113 C236.290333,134.006113 230.609239,106.554852 230.609239,72.6964154 C230.609239,38.837979 236.292786,11.3867175 243.303393,11.3867175 Z" fill="currentColor"></path>
                                </svg>
                            </a>
                        )}
                        {/* discord */}
                        {discordUrl && (
                            <a href={discordUrl} target="_blank" className="text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Discord">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"/>
                                </svg>
                            </a>
                        )}
                        {/* warpcast */}
                        {warpcastUrl && (
                            <a href={warpcastUrl} target="_blank" className="text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Warpcast">
                                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 1000 1000">
                                    <path d="M257.778 155.556H742.222V844.445H671.111V528.889H670.414C662.554 441.677 589.258 373.333 500 373.333C410.742 373.333 337.446 441.677 329.586 528.889H328.889V844.445H257.778V155.556Z" fill="currentColor"/>
                                    <path d="M128.889 253.333L157.778 351.111H182.222V746.667C169.949 746.667 160 756.616 160 768.889V795.556H155.556C143.283 795.556 133.333 805.505 133.333 817.778V844.445H382.222V817.778C382.222 805.505 372.273 795.556 360 795.556H355.556V768.889C355.556 756.616 345.606 746.667 333.333 746.667H306.667V253.333H128.889Z" fill="currentColor"/>
                                    <path d="M675.556 746.667C663.283 746.667 653.333 756.616 653.333 768.889V795.556H648.889C636.616 795.556 626.667 805.505 626.667 817.778V844.445H875.556V817.778C875.556 805.505 865.606 795.556 853.333 795.556H848.889V768.889C848.889 756.616 838.94 746.667 826.667 746.667V351.111H851.111L880 253.333H702.222V746.667H675.556Z" fill="currentColor"/>
                                </svg>
                            </a>
                        )}
                        {/* x (twitter) */}
                        {twitterUrl && (
                            <a href={twitterUrl} target="_blank" className="text-gray dark:text-bone dark:hover:text-light-gray" aria-label="X (Twitter)">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z"/>
                                </svg>
                            </a>
                        )}
                        {/* github */}
                        {githubUrl && (
                            <a href={githubUrl} target="_blank" className="text-gray dark:text-bone dark:hover:text-light-gray" aria-label="Github">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                                </svg>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;