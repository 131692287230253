const Hero = () => {
    return (
        <section className="overflow-hidden">
            <div className="flex flex-col sm:flex-row max-w-screen-xl items-center min-h-screen px-4 py-32 mx-auto lg:gap-8 xl:gap-0 lg:py-32">
                <div className="z-10 mr-auto text-center sm:text-start">
                    <h1 className="max-w-2xl mb-4 text-2xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-gray dark:text-bone">
                        A Trusted Blockchain Validator and a Facilitator of Interchain Utility Connections
                    </h1>
                    <p className="max-w-2xl mb-6 text-gray lg:mb-8 text-lg md:text-xl md:text-xl dark:text-bone">
                        Ksalab is a top-tier blockchain validator and a significant contributor to various networks. With just a few steps, you can entrust us with your funds and take advantage of our advanced infrastructure designed for enterprises. Furthermore, we provide a variety of tools that cater to the needs of node operators, programmers, and data analysts.
                    </p>
                    <a href="#networks" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-gray dark:text-bone rounded-2xl bg-jungle-green dark:bg-jungle-green dark:hover:text-light-gray">
                        Delegate
                        <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </a>
                    <a href="https://services.ksalab.xyz/" target="_blank" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray border border-gray rounded-2xl dark:text-bone dark:border-bone dark:hover:text-light-gray dark:hover:border-light-gray">
                        Services
                    </a> 
                </div>
                <div className="flex items-center w=1/2 mx-auto lg:mt-0 mt-8 relative">

                    <div className="absolute m-auto w-full">
                        <div className="relative m-auto">
                            <div className="relative m-auto">
                                <div className="relative m-auto flex w-full items-center justify-center">
                                    <span className="animate-orbit-fade-in absolute w-[350px] md:w-[450px] lg:w-[500px] xl:w-[600px]">
                                        <svg width="100%" height="100%" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="200" cy="200" r="190" fill="none" stroke="#5F5F5F" strokeWidth="1" strokeDasharray="10 9">
                                                <animateTransform attributeName="transform" type="rotate" from="0 200 200" to="360 200 200" dur="60s" repeatCount="indefinite" />
                                            </circle>
                                        </svg>
                                    </span>
                                    <span className="animate-orbit-fade-in absolute w-[250px] md:w-[320px] lg:w-[350px] xl:w-[400px]">
                                        <svg width="100%" height="100%" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="200" cy="200" r="190" fill="none" stroke="#5F5F5F" strokeWidth="1" strokeDasharray="10 9">
                                                <animateTransform attributeName="transform" type="rotate" from="360 200 200" to="0 200 200" dur="60s" repeatCount="indefinite" />
                                            </circle>
                                        </svg>
                                    </span>
                                    <span className="animate-orbit-fade-in absolute w-[150px] md:w-[206px]">
                                        <svg width="100%" height="100%" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="200" cy="200" r="190" fill="none" stroke="#5F5F5F" strokeWidth="1" strokeDasharray="10 9">
                                                <animateTransform attributeName="transform" type="rotate" from="0 200 200" to="360 200 200" dur="60s" repeatCount="indefinite" />
                                            </circle>
                                        </svg>
                                    </span>
                                    <div className="dynamic-size-in animate-orbit-logos-1 absolute flex items-center justify-center">
                                        <span className="animate-expand-orbit-1 [animation-delay:3s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(0deg)' }}>
                                            <span className="animate-rotate-counter absolute">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/bitcoin.png 64w, ./assets/logos/bitcoin.png 128w" src="./assets/logos/bitcoin.png 128w" style={{ color: 'transparent', transform: 'rotate(0deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                        <span className="animate-expand-orbit-1 [animation-delay:3s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(120deg)' }}>
                                            <span className="animate-rotate-counter absolute">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/eth.png 64w, ./assets/logos/eth.png 128w" src="./assets/logos/eth.png 128w" style={{ color: 'transparent', transform: 'rotate(-120deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                        <span className="animate-expand-orbit-1 [animation-delay:3s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(240deg)' }}>
                                            <span className="animate-rotate-counter absolute">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/cosmoshub.png 64w, ./assets/logos/cosmoshub.png 128w" src="./assets/logos/cosmoshub.png 128w" style={{ color: 'transparent', transform: 'rotate(-240deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="dynamic-size animate-orbit-logos-2 absolute flex items-center justify-center">
                                        <span className="animate-expand-orbit-2 [animation-delay:5s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(0deg)' }}>
                                            <span className="animate-rotate-counter-reverse">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/solana.png 64w, ./assets/logos/solana.png 128w" src="./assets/logos/solana.png 128w" style={{ color: 'transparent', transform: 'rotate(0deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                        <span className="animate-expand-orbit-2 [animation-delay:5s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(72deg)' }}>
                                            <span className="animate-rotate-counter-reverse">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/avalanche.png 64w, ./assets/logos/avalanche.png 128w" src="./assets/logos/avalanche.png 128w" style={{ color: 'transparent', transform: 'rotate(-72deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                        <span className="animate-expand-orbit-2 [animation-delay:5s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(144deg)' }}>
                                            <span className="animate-rotate-counter-reverse">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/near.png 64w, ./assets/logos/near.png 128w" src="./assets/logos/near.png 128w" style={{ color: 'transparent', transform: 'rotate(-144deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                        <span className="animate-expand-orbit-2 [animation-delay:5s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(216deg)' }}>
                                            <span className="animate-rotate-counter-reverse">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/ton.png 64w, ./assets/logos/ton.png 128w" src="./assets/logos/ton.png 128w" style={{ color: 'transparent', transform: 'rotate(-216deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                        <span className="animate-expand-orbit-2 [animation-delay:5s] absolute flex rounded-[100px] origin-center" style={{ transform: 'rotate(288deg)' }}>
                                            <span className="animate-rotate-counter-reverse">
                                                <img alt="Orbit Logo" loading="lazy" width="50" height="50" decoding="async" data-nimg="1" srcSet="./assets/logos/algorand.png 64w, ./assets/logos/algorand.png 128w" src="./assets/logos/algorand.png 128w" style={{ color: 'transparent', transform: 'rotate(-288deg)', filter: 'drop-shadow(4px 4px 2px gray)' }} />
                                            </span>
                                        </span>
                                    </div>
                                    <span className="absolute h-28 w-28 rounded-full bg-gray"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src="./hero_512.png" srcSet="./hero_256.png 256w, ./hero_512.png 512w" sizes="(max-width: 425px) 256px, 512px" alt="ksalab" style={{filter: 'drop-shadow(4px 4px 2px gray)'}} className="transition-transform duration-300 hover:scale-110" />
                </div>
            </div>
        </section>
    );
};

export default Hero;