import Nav from "../components/Nav";
import Hero from "../components/Hero";
// import FirstSection from "../components/FirstSection";
// import SecondSection from "../components/SecondSection";
import CustomerLogo from "../components/CustomerLogo";
import Projects from "../components/Projects";
import BlogPreview from "../components/BlogPreview";
import Footer from "../components/Footer";
// import Testimonial from "../components/Testimonial";
// import Faq from "../components/Faq";

const Home = ({ darkMode, toggleDarkMode }) => {
    return (
        <div>
            <Nav darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            <Hero />
            {/* <FirstSection /> */}
            {/* <SecondSection /> */}
            <CustomerLogo />
            <Projects />
            <BlogPreview />
            {/* <Faq /> */}
            <Footer />
        </div>
    );
};

export default Home;