import axios from 'axios';

//
export const getValidatorCommission = async (validatorAddress, apiUrl, networkType) => {
    if (!apiUrl || apiUrl.trim() === '') {
        return null;
    }

    // Check if "Cosmos" is present in the network types
    const isCosmosNetwork = networkType.some(type => type.toLowerCase() === 'cosmos');
    
    if (!isCosmosNetwork) {
        return null;
    }

    try {
        const response = await axios.get(`${apiUrl}/cosmos/staking/v1beta1/validators/${validatorAddress}`);
        const validator = response.data.validator;
        const commissionRate = validator.commission.commission_rates.rate;
        return commissionRate;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// Receiving Token Name
export const getTokenName = async (apiUrl, networkType) => {
    if (!apiUrl || apiUrl.trim() === '') {
        return null;
    }

    // Check if "Cosmos" is present in the network types
    const isCosmosNetwork = networkType.some(type => type.toLowerCase() === 'cosmos');
    
    if (!isCosmosNetwork) {
        return null;
    }

    try {
        const response = await axios.get(`${apiUrl}/cosmos/staking/v1beta1/params`);
        return response.data.params.bond_denom;
    } catch (error) {
        return null;
    }
};

// Function to check token price based on project data
export const getTokenPrice = async (project) => {
    if (!project || !project.checkPrice || !project.token_id) {
        return null;
    }

    const checkPriceType = project.checkPrice.toUpperCase();

    try {
        switch (checkPriceType) {
            case 'COINGECKO':
                return await getTokenPrice_coingecko(project.token_id.coingecko);
            case 'COINCODEX':
                return await getTokenPrice_coincodex(project.token_id.coincodex);
            default:
                return null;
        }
    } catch (error) {
        return null;
    }
};

// Receiving Token Prices from Coingecko
export const getTokenPrice_coingecko = async (tokenId) => {
    const coingeckoApiKey = import.meta.env.VITE_API_COINGECKO;
    const coingeckoApiUrl = import.meta.env.VITE_API_COINGECKO_URL;

    if (!tokenId || tokenId.trim() === '' || !coingeckoApiKey || coingeckoApiKey.trim() === '' || !coingeckoApiUrl || coingeckoApiUrl.trim() === '') {
        return null;
    }

    try {
        const response = await axios.get(`${coingeckoApiUrl}/coins/${tokenId}?x_cg_api_key=${coingeckoApiKey}`);
        const data = response.data;
        const usdPrice = data.market_data.current_price.usd;
        const eurPrice = data.market_data.current_price.eur;
        return usdPrice, eurPrice;
    } catch (error) {
        return null;
    }
};

// Receiving Token Prices from CoinCodex
export const getTokenPrice_coincodex = async (tokenName) => {
    const coincodexApiUrl = import.meta.env.VITE_API_COINCODEX_URL;

    if (!tokenName || tokenName.trim() === '') {
        return null;
    }

    try {
        const response = await axios.get(`${coincodexApiUrl}/get_coin/${tokenName}`);
        const data = response.data;
        const usdPrice = data.last_price_usd;
        return usdPrice;
    } catch (error) {
        return null;
    }
};

// Receiving Annual Payments
export const getAnnualProvisions = async (apiUrl, check) => {
    if (!apiUrl || apiUrl.trim() === '' || !check || check !== 1) {
        return null;
    }

    try {
        const response = await axios.get(`${apiUrl}/cosmos/mint/v1beta1/annual_provisions`);
        return parseFloat(response.data.annual_provisions);
    } catch (error) {
        return null;
    }
};

// Getting the Total Number of Blocked Tokens
export const getTotalStaked = async (apiUrl) => {
    if (!apiUrl || apiUrl.trim() === '') {
        return null;
    }

    try {
        const response = await axios.get(`${apiUrl}/cosmos/staking/v1beta1/pool`);
        const { bonded_tokens, not_bonded_tokens } = response.data.pool;
        return parseFloat(bonded_tokens) + parseFloat(not_bonded_tokens);
    } catch (error) {
        return null;
    }
};

// Calculate APR
export const calculateAPR = (annualProvisions, totalStaked) => {
    if (!annualProvisions || !totalStaked) return 0;
    return ((annualProvisions / totalStaked) * 100).toFixed(2); // APR formula with 2 decimal places
};
