import { useState } from "react";
import ProjectCard from './ProjectCard';
import ProjectCardCalcModal from './ProjectCardCalcModal';
import projectData from '../assets/data.json';


const Projects = () => {
    const [activeTab, setActiveTab] = useState('mainnet');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const sections = Object.keys(projectData);

    const openModal = (project) => {
        setSelectedProject(project);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <section id="networks">
            <div className="mx-auto max-w-screen-md text-center my-8 lg:mb-12">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray dark:text-bone">Networks</h2>
                <p className="mb-5 font-light text-gray sm:text-xl dark:text-bone">
                    Various blockchain networks are presented here, including both mainnet and testnet networks.
                </p>
            </div>

            {/* Tabs */}
            <div className="items-center py-2 px-4 mx-auto max-w-screen-xl sm:py-4 lg:px-6 mb-4">
                <ul className="flex flex-wrap justify-center -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content"  role="tablist">
                    {sections.map(section => (
                        <li key={section} className="me-2" role="presentation">
                            <button className={`inline-block p-4 rounded-t-lg text-gray dark:text-bone dark:hover:text-light-gray ${activeTab === section ? 'border-b-2' : ''}`} onClick={() => setActiveTab(section)} id={`${section}-styled-tab`} data-tabs-target={`#styled-${section}`} type="button" role="tab"  aria-hidden="false">
                                {section.charAt(0).toUpperCase() + section.slice(1)}
                                {projectData[section] && projectData[section].length ? (
                                    <span className="mx-1 py-0 px-1 rounded-md border border-gray dark:border-bone">{projectData[section].length}</span> 
                                ) : (
                                    ''
                                )}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Tab Content */}
            <div className="mx-auto py-2 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-6 " id="default-styled-tab-content">
                {sections.map((section) => (
                    <div key={section} className={`${activeTab === section ? '' : 'hidden'} p-4`} id={`styled-${section}`} role="tabpanel" aria-labelledby={`${section}s-tab`}>
                        {/* Cards */}
                        {projectData[section] && projectData[section].length > 0 ? (
                        <div className="mb-4 grid gap-4 grid-cols-2 sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-4">
                            {/* Card */}
                            {projectData[section].sort((a, b) => a.name.localeCompare(b.name)).map((project, index) => (
                                <ProjectCard key={index} project={project} openModal={openModal} />
                            ))}
                        </div>
                        ) : (
                            <div className="text-center py-4 text-gray dark:text-bone">Data unavailable</div>
                        )}
                    </div>
                ))}
            </div>

            <ProjectCardCalcModal isOpen={isModalOpen} onClose={closeModal} project={selectedProject} />
        </section>
    );
};

export default Projects;