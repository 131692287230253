const logos = import.meta.glob('../assets/logos/*.{png,jpg,jpeg,svg}', { eager: true });

// Convert the object to an array
const logoPaths = Object.keys(logos).sort(() => Math.random() - 0.5);
const logoPathsReverce = Object.keys(logos).sort(() => Math.random() - 0.5);


const CustomerLogo = () => {
    return (
        <section>
            <div className="mx-auto max-w-screen-xl">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray dark:text-bone md:text-4xl">
                    Our networks and relationships
                </h2>
                <p className="mb-8 lg:mb-16 text-xl text-center text-gray dark:text-bone md:text-xl">
                    We are represented in these networks or just work with these networks
                </p>
                <div className="h-16 items-wrap relative flex overflow-hidden select-none gap-5 before:content-[''] before:h-full before:top-0 before:w-1/6 before:absolute before:z-10 before:pointer-events-none before:left-0 before:bg-gradient-to-r before:from-light-gray before:dark:from-gray before:to-60% after:content-[''] after:h-full after:top-0 after:w-1/6 after:absolute after:z-10 after:pointer-events-none after:right-0 after:bg-gradient-to-l after:from-light-gray after:dark:from-gray after:to-60%">
                    <div className="flex shrink-0 gap-5 justify-around min-w-full animate-scroll marquee"> {/* items */}
                        {logoPaths.map((path, index) => {
                            // Declare the altText variable inside the map function
                            const altText = path.split('/').pop().split('.')[0];
                            return (
                                <img key={index} src={logos[path].default} alt={altText} title={altText} className="h-12 rounded-md my-2.5 grayscale ease-in before:content[counter(item)] hover:scale-105 hover:grayscale-0 item h-12 mx-4" />
                            );
                        })}
                    </div>
                    <div aria-hidden="true" className="flex shrink-0 gap-5 justify-around min-w-full hover:[animation-play-state:paused] animate-scroll marquee">
                        {logoPaths.map((path, index) => {
                            // Declare the altText variable inside the map function
                            const altText = path.split('/').pop().split('.')[0];
                            return (
                                <img key={index} src={logos[path].default} alt={altText} title={altText} className="h-12 rounded-md my-2.5 grayscale ease-in before:content[counter(item)] hover:scale-105 hover:grayscale-0 item h-12 mx-4" />
                            );
                        })}
                    </div>
                </div>
                <div className="h-16 items-wrap relative flex overflow-hidden select-none gap-5 before:content-[''] before:h-full before:top-0 before:w-1/6 before:absolute before:z-10 before:pointer-events-none before:left-0 before:bg-gradient-to-r before:from-light-gray before:dark:from-gray before:to-60% after:content-[''] after:h-full after:top-0 after:w-1/6 after:absolute after:z-10 after:pointer-events-none after:right-0 after:bg-gradient-to-l after:from-light-gray after:dark:from-gray after:to-60%">
                    <div className="flex shrink-0 gap-5 justify-around min-w-full animate-scroll marquee reverce">
                        {logoPathsReverce.map((path, index) => {
                            // Declare the altText variable inside the map function
                            const altText = path.split('/').pop().split('.')[0];
                            return (
                                <img key={index} src={logos[path].default} alt={altText} title={altText} className="h-12 rounded-md my-2.5 grayscale ease-in before:content[counter(item)] hover:scale-105 hover:grayscale-0 item h-12 mx-4" />
                            );
                        })}
                    </div>
                    <div aria-hidden="true" className="flex shrink-0 gap-5 justify-around min-w-full animate-scroll marquee reverce">
                        {logoPathsReverce.map((path, index) => {
                            // Declare the altText variable inside the map function
                            const altText = path.split('/').pop().split('.')[0];
                            return (
                                <img key={index} src={logos[path].default} alt={altText} title={altText} className="h-12 rounded-md my-2.5 grayscale ease-in before:content[counter(item)] hover:scale-105 hover:grayscale-0 item h-12 mx-4" />
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomerLogo;